import ReactDOM from "react-dom";
import React from "react";
import Blog from "./library/_blog";

export default class TheWasteBlog {
  constructor() {
    this.renderBlogComponenent();
  }

  renderBlogComponenent() {
    fetch("/wp-json/wp/v2/posts?categories=16")
      .then((response) => response.json())
      .then((data) => {
        if (data.length > 0) {
          ReactDOM.render(
            <Blog category="16" filters="false" />,
            document.querySelector("#blog-root")
          );
        }
      });
  }
}
